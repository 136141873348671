import { ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { getEmployabilityEntities } from '@reducers/orm/employability/employability.selector';
import get from 'lodash-es/get';
import mapValues from 'lodash-es/mapValues';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Features } from '../../../../enums';
import { AppState } from '../../../../reducers';
import { contractInfoPerEmployeePerDay, getContracts } from '../../../../reducers/orm/contract/contract.service';
import { EmployeeModel } from '../../../../reducers/orm/employee/employee.model';
import { getEmployeeEntities } from '../../../../reducers/orm/employee/employee.service';
import { EmployeeStatusModel, OpenShiftUserStatus } from '../../../../reducers/orm/open-shift/open-shift.model';
import { OpenShiftStatusFilterTypes } from './sb-table-select.model';

export interface TableSelectFilter {
  value: string;
  name: string;
}

@Component({
  selector: 'sb-table-select',
  templateUrl: 'sb-table-select.component.html',
  styleUrls: ['sb-table-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SbTableSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public employees: EmployeeModel[];

  @Input()
  public instancesRemaining: number;
  @Input()
  public date: string;
  @Input()
  public startTime: string;
  @Input()
  public endTime: string;
  @Input()
  public shiftId: string;
  @Input()
  public EmployeeStatusEntities: Record<string, EmployeeStatusModel> = {};
  @Input()
  public height = 510;

  @Input()
  public assignTmp: TemplateRef<any>;
  @Input()
  public filters: TableSelectFilter[] = [];
  @Input()
  private enabledFilters = [];

  public checkboxDisabled: boolean;

  public filterGroup = new UntypedFormGroup({});

  public filterCtrl = new UntypedFormArray([]);
  public searchCtrl = new UntypedFormControl('');

  public filteredRows: EmployeeModel[] = [];

  private dataSubs = new Subscription();

  public positions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' }, 0, 8),
  ];

  @Output()
  public selectionChanged = new EventEmitter<EmployeeModel[]>();

  public selected: EmployeeModel[] = [];
  public selectedEntities: Record<string, boolean> = {};
  public features = Features;

  public employeeOptions: Record<string, EmployeeModel> = {};

  public constructor(
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.dataSubs.add(this.getEmployees());

    this.filters.map((filter) =>
      this.filterGroup.addControl(
        filter.value.toString(),
        new UntypedFormControl(this.enabledFilters.includes(filter.value.toString())),
      ),
    );

    this.dataSubs.add(
      this.filterGroup.valueChanges.pipe(distinctUntilChanged()).subscribe((filters: Record<string, boolean>) => {
        this.filterRows(filters, this.searchCtrl.value);
      }),
    );

    this.dataSubs.add(
      this.searchCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe((searchVal: string) => {
        this.filterRows(this.filterGroup.value, searchVal.toLocaleLowerCase());
      }),
    );

    this.filterRows(this.filterGroup.value, '');
  }

  private filterRows(activeFilters: Record<string, boolean> | undefined, searchVal: string) {
    this.filteredRows = this.employees.filter((employee) => {
      if (!activeFilters[OpenShiftStatusFilterTypes.NOT_INVITED] && !this.EmployeeStatusEntities[employee.id]?.status) {
        return false;
      }

      if (
        !activeFilters[OpenShiftStatusFilterTypes.PENDING] &&
        this.EmployeeStatusEntities[employee.id]?.status === OpenShiftUserStatus.PENDING
      ) {
        return false;
      }

      if (
        !activeFilters[OpenShiftStatusFilterTypes.ASSIGNED] &&
        this.EmployeeStatusEntities[employee.id]?.status === OpenShiftUserStatus.ASSIGNED
      ) {
        return false;
      }

      if (
        !activeFilters[OpenShiftStatusFilterTypes.DECLINED] &&
        this.EmployeeStatusEntities[employee.id]?.status === OpenShiftUserStatus.DECLINED
      ) {
        return false;
      }

      if (
        !activeFilters[OpenShiftStatusFilterTypes.REQUESTED] &&
        this.EmployeeStatusEntities?.[employee.id]?.status === OpenShiftUserStatus.REQUESTED
      ) {
        return false;
      }

      if (!!searchVal && !employee.name.toLocaleLowerCase().includes(searchVal)) {
        return false;
      }

      if (
        !activeFilters?.[OpenShiftStatusFilterTypes.INCOMPATIBLE_EMPLOYEES] &&
        !!this.employeeOptions[employee.id]?.employability &&
        !this.employeeOptions[employee.id]?.employability?.employable
      ) {
        return false;
      }

      return true;
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.instancesRemaining) {
      this.selected = [];
    }
  }

  public ngOnDestroy() {
    this.dataSubs.unsubscribe();
  }

  private getEmployees() {
    return combineLatest([
      this.store.select(getEmployeeEntities),
      this.store.select(getContracts),
      this.store.select(getEmployabilityEntities),
    ])
      .pipe(
        map(([employees, contracts, employability]) => {
          const contractPerDay = contractInfoPerEmployeePerDay(this.date, this.date, contracts);

          return mapValues(employees, (employee: EmployeeModel) => {
            const wage = get(contractPerDay, `${employee.id}.${this.date}.wage`, '0.00');

            const employeeEmployability = employability[employee.id];
            const onlyLackSkills =
              !employeeEmployability?.employable &&
              !!employeeEmployability?.details &&
              !employeeEmployability?.details?.schedule?.length &&
              !employeeEmployability?.details?.absence?.length &&
              !employeeEmployability?.details?.availability;
            return {
              ...employee,
              wage,
              employability: employeeEmployability,
              onlyLackSkills,
            };
          });
        }),
      )
      .subscribe((employeeOptions) => {
        this.employeeOptions = employeeOptions;
      });
  }

  public selectEmployee(event: Event, employee: EmployeeModel) {
    if (this.checkboxDisabled && !this.selectedEntities[employee.id]) {
      return;
    }

    if (this.selectedEntities[employee.id]) {
      this.selected = this.selected.filter((e) => e.id !== employee.id);
      this.selectionChange(this.selected);
      return;
    }
    this.selected = [...this.selected, employee];
    this.selectionChange(this.selected);
  }

  public selectionChange(employees: EmployeeModel[]) {
    this.selectedEntities = {};
    employees.forEach((employee) => {
      this.selectedEntities[employee.id] = true;
    });
    this.updateCheckboxState();
    this.selectionChanged.emit(employees);
  }

  private updateCheckboxState() {
    this.checkboxDisabled = this.instancesRemaining <= this.selected.length;
  }

  public trackBy(_: number, filter: TableSelectFilter): string {
    return filter.value;
  }
}
