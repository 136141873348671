<div class="flex justify-between w-full pr-2">
  <div class="flex flex-1">
    <sb-avatar [employee]="employee" [size]="30"></sb-avatar>
    <span class="font-semibold text-base ml-4 self-center">{{ employee.name }}</span>
  </div>
  <div class="self-center text-sm mr-2">
    <ng-container *subscriptionPlan="planType.BASIC">
      <span *permission="'View salary'; user: employee.id">
        {{ employee.wage | money }}
      </span>
    </ng-container>
  </div>
  <div class="self-center">
    <ng-container [ngTemplateOutlet]="assignTmp" [ngTemplateOutletContext]="{ employee: employee }"></ng-container>
  </div>
</div>

<ng-container *subscriptionPlan="planType.BASIC">
  <ng-container *ngIf="!!employee.employability?.details.availability">
    <select-availability-conflict
      [availability]="employee.employability.details.availability"
    ></select-availability-conflict>
  </ng-container>
</ng-container>

<ng-container *subscriptionPlan="planType.BASIC">
  <ng-container
    *ngFor="let absence of employee.employability?.details?.absence; let isFirst = first; trackBy: trackById"
  >
    <select-absence-conflict [isFirst]="isFirst" [absence]="absence"></select-absence-conflict>
  </ng-container>
</ng-container>

<ng-container *ngFor="let shift of employee.employability?.details?.schedule; let isFirst = first; trackBy: trackById">
  <select-schedule-conflict [isFirst]="isFirst" [shift]="shift"></select-schedule-conflict>
</ng-container>

<ng-container *subscriptionPlan="planType.EARLY_ADOPTER">
  <ng-container *ngIf="employee.employability?.details?.missingSkills?.length">
    <select-skill-conflict [skills]="employee.employability?.details?.missingSkills"></select-skill-conflict>
  </ng-container>
</ng-container>
